import { StudyCycleEnum, StudyProgramApi, StudyProgramDTO, StudyProgramUpdateDTO } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new StudyProgramApi(getAuthConfiguration());

export const getStudyProgram = (id: string) => {
    if (!id) {
        return {} as StudyProgramDTO;
    }
    return ApiFactory().apiStudyProgramGetStudyProgramByIdIdGet({ id });
}

export const getStudyPrograms = (namePattern?: string, facultyIds?: string[], domainIds?: string[], page: number = 1, pageSize: number = 10, studyCycle: StudyCycleEnum = StudyCycleEnum.Bachelor) => {
    return ApiFactory().apiStudyProgramGetAllStudyProgramsGet({ page, pageSize, namePattern, facultyIds, domainIds, studyCycle });
}

export const addStudyProgram = (studyProgramUpdateDTO: StudyProgramUpdateDTO) => {
    return ApiFactory().apiStudyProgramAddStudyprogramPost({ studyProgramUpdateDTO });
}

export const updateStudyProgram = (id: string, studyProgramUpdateDTO: StudyProgramUpdateDTO) => { 
    return ApiFactory().apiStudyProgramUpdateStudyProgramIdPut({ id, studyProgramUpdateDTO });
}

export const deleteStudyProgram = (id: string) => {
    return ApiFactory().apiStudyProgramDeleteIdDelete({ id });
}