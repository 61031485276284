/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Domain,
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';
import {
    StudyProgram,
    StudyProgramFromJSON,
    StudyProgramFromJSONTyped,
    StudyProgramToJSON,
} from './StudyProgram';
import {
    UniversityCenter,
    UniversityCenterFromJSON,
    UniversityCenterFromJSONTyped,
    UniversityCenterToJSON,
} from './UniversityCenter';

/**
 * 
 * @export
 * @interface Faculty
 */
export interface Faculty {
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Faculty
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Faculty
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    universityCenterId?: string;
    /**
     * 
     * @type {UniversityCenter}
     * @memberof Faculty
     */
    universityCenter?: UniversityCenter;
    /**
     * 
     * @type {Array<Domain>}
     * @memberof Faculty
     */
    domains?: Array<Domain> | null;
    /**
     * 
     * @type {Array<StudyProgram>}
     * @memberof Faculty
     */
    studyPrograms?: Array<StudyProgram> | null;
}

export function FacultyFromJSON(json: any): Faculty {
    return FacultyFromJSONTyped(json, false);
}

export function FacultyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Faculty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'universityCenterId': !exists(json, 'universityCenterId') ? undefined : json['universityCenterId'],
        'universityCenter': !exists(json, 'universityCenter') ? undefined : UniversityCenterFromJSON(json['universityCenter']),
        'domains': !exists(json, 'domains') ? undefined : (json['domains'] === null ? null : (json['domains'] as Array<any>).map(DomainFromJSON)),
        'studyPrograms': !exists(json, 'studyPrograms') ? undefined : (json['studyPrograms'] === null ? null : (json['studyPrograms'] as Array<any>).map(StudyProgramFromJSON)),
    };
}

export function FacultyToJSON(value?: Faculty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'universityCenterId': value.universityCenterId,
        'universityCenter': UniversityCenterToJSON(value.universityCenter),
        'domains': value.domains === undefined ? undefined : (value.domains === null ? null : (value.domains as Array<any>).map(DomainToJSON)),
        'studyPrograms': value.studyPrograms === undefined ? undefined : (value.studyPrograms === null ? null : (value.studyPrograms as Array<any>).map(StudyProgramToJSON)),
    };
}

