/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StudyCycleEnum,
    StudyCycleEnumFromJSON,
    StudyCycleEnumToJSON,
    StudyProgramDTO,
    StudyProgramDTOFromJSON,
    StudyProgramDTOToJSON,
    StudyProgramDTOPagedResponse,
    StudyProgramDTOPagedResponseFromJSON,
    StudyProgramDTOPagedResponseToJSON,
    StudyProgramUpdateDTO,
    StudyProgramUpdateDTOFromJSON,
    StudyProgramUpdateDTOToJSON,
} from '../models';

export interface ApiStudyProgramAddStudyprogramPostRequest {
    studyProgramUpdateDTO?: StudyProgramUpdateDTO;
}

export interface ApiStudyProgramDeleteIdDeleteRequest {
    id: string;
}

export interface ApiStudyProgramGetAllStudyProgramsGetRequest {
    namePattern?: string;
    facultyIds?: Array<string>;
    domainIds?: Array<string>;
    studyCycle?: StudyCycleEnum;
    page?: number;
    pageSize?: number;
}

export interface ApiStudyProgramGetStudyProgramByIdIdGetRequest {
    id: string;
}

export interface ApiStudyProgramUpdateStudyProgramIdPutRequest {
    id: string;
    studyProgramUpdateDTO?: StudyProgramUpdateDTO;
}

/**
 * 
 */
export class StudyProgramApi extends runtime.BaseAPI {

    /**
     */
    async apiStudyProgramAddStudyprogramPostRaw(requestParameters: ApiStudyProgramAddStudyprogramPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/StudyProgram/AddStudyprogram`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StudyProgramUpdateDTOToJSON(requestParameters.studyProgramUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiStudyProgramAddStudyprogramPost(requestParameters: ApiStudyProgramAddStudyprogramPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiStudyProgramAddStudyprogramPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiStudyProgramDeleteIdDeleteRaw(requestParameters: ApiStudyProgramDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStudyProgramDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/StudyProgram/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiStudyProgramDeleteIdDelete(requestParameters: ApiStudyProgramDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiStudyProgramDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiStudyProgramGetAllStudyProgramsGetRaw(requestParameters: ApiStudyProgramGetAllStudyProgramsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudyProgramDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.facultyIds) {
            queryParameters['FacultyIds'] = requestParameters.facultyIds;
        }

        if (requestParameters.domainIds) {
            queryParameters['DomainIds'] = requestParameters.domainIds;
        }

        if (requestParameters.studyCycle !== undefined) {
            queryParameters['StudyCycle'] = requestParameters.studyCycle;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/StudyProgram/GetAllStudyPrograms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyProgramDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiStudyProgramGetAllStudyProgramsGet(requestParameters: ApiStudyProgramGetAllStudyProgramsGetRequest = {}, initOverrides?: RequestInit): Promise<StudyProgramDTOPagedResponse> {
        const response = await this.apiStudyProgramGetAllStudyProgramsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudyProgramGetStudyProgramByIdIdGetRaw(requestParameters: ApiStudyProgramGetStudyProgramByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudyProgramDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStudyProgramGetStudyProgramByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/StudyProgram/GetStudyProgramById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyProgramDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiStudyProgramGetStudyProgramByIdIdGet(requestParameters: ApiStudyProgramGetStudyProgramByIdIdGetRequest, initOverrides?: RequestInit): Promise<StudyProgramDTO> {
        const response = await this.apiStudyProgramGetStudyProgramByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudyProgramUpdateStudyProgramIdPutRaw(requestParameters: ApiStudyProgramUpdateStudyProgramIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStudyProgramUpdateStudyProgramIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/StudyProgram/UpdateStudyProgram/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StudyProgramUpdateDTOToJSON(requestParameters.studyProgramUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiStudyProgramUpdateStudyProgramIdPut(requestParameters: ApiStudyProgramUpdateStudyProgramIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiStudyProgramUpdateStudyProgramIdPutRaw(requestParameters, initOverrides);
    }

}
