import { useState } from "react";
import Layout from "../../../Containers/Layout";
import styles from "./StudyProgramForm.module.scss";
import { Form, DatePicker, Select, Col, Row } from "antd";
import CustomForm from "../../../CustomComponents/CustomForm";
import { theme } from "../../../theme";
import { Redirect, useParams } from "react-router-dom";
import {
  NOTIFICATION_TYPES,
  openNotification,
} from "../../Notifications/NotificationsUtils";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useDebounce from "../../../Hooks/debounce";
import { getDomains } from "../../../Requests/domain-requests";
import { AccreditationTypeEnum, StudyCycleEnum } from "../../../Api";
import { addStudyProgram, getStudyProgram, updateStudyProgram } from "../../../Requests/study-program-request";
import moment from 'moment'
import { getFaculties } from "../../../Requests/faculty-requests";
import { dateFormat } from "../../../utils/constants";

const { Option } = Select;


const StudyProgramForm = (props: any) => {
  const { t, i18n } = useTranslation();

  const openGetErrorNotification = (_error: any) => {
    openNotification(
      'Eroare',
      'Datele nu au putut fi aduse din server!',
      NOTIFICATION_TYPES.ERROR
    );
  };
  const [redirect, setRedirect] = useState(false);

  const [departmentSearch, setDepartmentSearch] = useState("");
  const debounceDepartmentSearch = useDebounce(departmentSearch, 400);
  const [form] = Form.useForm<{
    name: string | null,
    facultyId: string,
    domainId: string;
    numberECTS: number,
    externalEvaluationHistory: string | null,
    accreditationType: AccreditationTypeEnum,
    capacity: number,
    educaionalDomainISCED: string | null,
    codCOR: string | null,
    ocupationName: string | null,
    ancNoticeNumber: string | null,
    ancNoticeDate: Date,
    aracisNoticeDate: Date
    programSupervisor: string | null,
  }>();
  const { programId, studyCycle } = useParams<{ programId: string, studyCycle: StudyCycleEnum }>();


  const { data: program } = useQuery(["getProgram", programId], () => getStudyProgram(programId), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {
      if (data) {
        form.setFieldsValue({
            name: data.name,
            // faculties: data.faculties,
            facultyId: data.facultyId,
            domainId: data.domainId,
            numberECTS: data.numberECTS,
            accreditationType: data.accreditationType,
            externalEvaluationHistory: data.externalEvaluationHistory,
            capacity: data.capacity,
            educaionalDomainISCED: data.educaionalDomainISCED,
            codCOR: data.codCOR,
            ocupationName: data.ocupationName,
            ancNoticeNumber: data.ancNoticeNumber,
            ancNoticeDate: moment(data.ancNoticeDate),
            aracisNoticeDate: moment(data.aracisNoticeDate),
            programSupervisor: data.programSupervisor
        });
      }
    },
    refetchOnWindowFocus: false
  });

const openFetchError = (_error: any) => {
  openNotification(
      'Eroare!',
      'Datele nu au putut fi aduse din server!',
      NOTIFICATION_TYPES.ERROR
  );
}

  const openSaveErrorNotification = (error: any) => {
    if (error.status === 400) {
      openNotification(
        'Eroare!',
        'Datele completate nu sunt valide!',
        NOTIFICATION_TYPES.ERROR
      );
    } else {
      openNotification(
        'Eroare!',
        'Propunerea nu a putut fi salvată!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  const handleCancel = () => {
    setRedirect(true);
  };

  const openSuccessNotificationAndRedirect = () => {
    setRedirect(true);
    openNotification(
      'Succes!',
      'Salvarea s-a efectuat cu succes!',
      NOTIFICATION_TYPES.SUCCESS,
      6
    );
  }

  const faculty = Form.useWatch('facultyId', form);

  const { data: domains } = useQuery(['getDomains', faculty],
        () => {
            const state = form.getFieldsValue();
            return getDomains(undefined, state.facultyId === undefined ? undefined : [state.facultyId], studyCycle, 1, 20);
        },
        {
            onError: openFetchError,
        }
    );

    const { data: faculties } = useQuery(['getFaculties'],
        () => {
            return getFaculties(undefined, 1, 20);
        },
        {
            onError: openFetchError,
        }
    );

  const handleSave = () => {
    const state = form.getFieldsValue();
    console.warn(state);
    
   //TODO validation
    if (state.name !== "") {
      let obj = {
        name: state.name,
        facultyId: state.facultyId,
        domainId: state.domainId,
        numberECTS: state.numberECTS,
        accreditationType: state.accreditationType,
        externalEvaluationHistory: state.externalEvaluationHistory,
        capacity: state.capacity,
        educaionalDomainISCED: state.educaionalDomainISCED,
        codCOR: state.codCOR,
        ocupationName: state.ocupationName,
        ancNoticeNumber: state.ancNoticeNumber,
        ancNoticeDate: state.ancNoticeDate,
        aracisNoticeDate: state.aracisNoticeDate,
        studyCycle: studyCycle,
        programSupervisor: state.programSupervisor
      };
      (programId ? updateStudyProgram(programId, obj) : addStudyProgram(obj))
      .then(openSuccessNotificationAndRedirect)
      .catch(openSaveErrorNotification);
    } else {
      openNotification(
        'Eroare!',
        'Datele introduse nu sunt valide!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };
  

  if (redirect) {
    return <Redirect to={'/' + studyCycle + "/programe"} />;
  } else {
    return (
      <Layout>
        <div className={styles.container}>
          <CustomForm
            form={form}
            layout={theme.layout}
            action="/programe"
            boxshadow={"none"}
          >
            <div className={styles.firstLine}>    

              <Row gutter={8}>
                

                {/* select facultati */}
                <Col span={12}>
                    <Form.Item
                      name='facultyId'
                      label='Facultate:'
                      rules={[
                        {
                          required: true,
                          message: 'Selectarea facultății este obligatorie!',
                          whitespace: false,
                          
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%", textAlign: "left" }}
                        placeholder='Alege facultea'
                      >
                        {
                          faculties?.map((option: any) => (
                              <Option key={option.id}>{option.name}</Option>
                          )) ?? [] }
                        
                      </Select>
                    </Form.Item>
                </Col>

                 {/* select facultati */}
                 <Col span={12}>
                    <Form.Item
                      name='domainId'
                      label='Domeniu:'
                      rules={[
                        {
                          required: true,
                          message: 'Selectarea domeniului este obligatorie!',
                          whitespace: false,
                          
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%", textAlign: "left" }}
                        placeholder='Alege domeniul'
                      >
                        {
                          domains?.data?.map((option: any) => (
                              <Option key={option.id}>{option.name}</Option>
                          )) ?? [] }
                      </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name='name'
                    label='Denumirea programului de studii:'
                    rules={[
                      {
                        required: true,
                        message: 'Denumirea programului de studii este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează denumirea programului de studii'
                      />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name='numberECTS'
                    label='Nr. ECTS:'
                    rules={[
                      {
                        required: true,
                        message: 'Nr. ECTS este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <Select
                        allowClear
                        style={{ width: "100%", textAlign: "left" }}
                        placeholder='Completează nr. ECTS'
                      >
                        {
                        studyCycle === StudyCycleEnum.Bachelor ?
                        [
                          <Option value='180'>180</Option>,
                          <Option value='240'>240</Option>
                        ] :
                        [
                          <Option value='60'>60</Option>,
                          <Option value='90'>90</Option>,
                          <Option value='120'>120</Option>,
                        ]
                        }   
                    </Select>
                  </Form.Item>
                </Col>
                

                <Col span={6}>
                  <Form.Item
                    name='accreditationType'
                    label='Acreditare:'
                    rules={[
                      {
                        required: true,
                        message: 'Selectarea acreditarii este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <Select
                        allowClear
                        style={{ width: "100%", textAlign: "left" }}
                        placeholder='Alege tipul acreditării'
                      >
                        <Option value='A'>A</Option>
                        <Option value='AP'>AP</Option>
                        
                    </Select>
                  </Form.Item>
                </Col>
                

                <Col span={6}>
                  <Form.Item
                    name='capacity'
                    label='Capacitate de școlarizare:'
                    rules={[
                      {
                        required: true,
                        message: 'Capacitatea de școlarizare este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează capacitatea'
                      />
                  </Form.Item>
                </Col>
                
                <Col span={6}>
                  <Form.Item
                    name='programSupervisor'
                    label='Responsabil program:'
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează responsabilul de program'
                      />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name='educaionalDomainISCED'
                    label='Domeniu educational ISCED:'
                    rules={[
                      {
                        required: true,
                        message: 'Domeniu educational ISCED 2013 este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează domeniu educational'
                      />
                  </Form.Item>
                </Col>
                

                <Col span={8}>
                  <Form.Item
                    name='codCOR'
                    label='Cod COR:'
                    rules={[
                      {
                        required: true,
                        message: 'Codul COR este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează codul COR'
                      />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name='ocupationName'
                    label='Denumire ocupație:'
                    rules={[
                      {
                        required: true,
                        message: 'Denumirea ocupației este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează denumirea ocupației'
                      />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name='externalEvaluationHistory'
                    label='Istoric evaluări externe:'
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează istoricul evaluărilor externe'
                      />
                  </Form.Item>
                </Col>      

                <Col span={8}>
                  <Form.Item
                    name='ancNoticeNumber'
                    label='Nr. aviz ANC:'
                    rules={[
                      {
                        required: true,
                        message: 'Nr. aviz ANC este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează nr. aviz ANC'
                      />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name='ancNoticeDate'
                    label='Data aviz ANC:'
                    rules={[
                      {
                        required: true,
                        message: 'Data aviz ANC este obligatorie!',
                        type: 'date'
                      },
                    ]}
                    >
                    <DatePicker
                      placeholder='Completează data aviz ANC'
                      format={dateFormat}/>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name='aracisNoticeDate'
                    label='Data aviz ARACIS:'
                    rules={[
                      {
                        required: true,
                        message: 'Data aviz ARACIS este obligatorie!',
                        type: 'date'
                      },
                    ]}
                    >
                    <DatePicker
                      placeholder='Completează data aviz ARACIS'
                      format={dateFormat}
                      />
                  </Form.Item>
                </Col>
                
                {/* select programe de studii */}
                {/* <Col span={24}>
                    <Form.Item
                      name='studyPrograms'
                      label='Programe de studiu asociate:'
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        style={{ width: "100%", textAlign: "left" }}
                        placeholder='Alege programele de studiu asociate'
                      >
                        <Option value='M_CTI'>Calculatoare si tehnologia informţiei</Option>
                        <Option value='M_Auto'>Ingineria autovehiculelor</Option>
                        <Option value='M_Materiale'>Ingineria materialelor</Option>
                        <Option value='M_Mediu'>Ingineria mediului</Option>
                        <Option value='M_Alimente'>Ingineria produselor alimentare</Option>
                        <Option value='M_Sisteme'>Ingineria sistemelor</Option>
                        <Option value='M_Transporturi'>Ingineria transporturilor</Option>
                        <Option value='M_Aerospatiala'>Inginerie aerospaţială</Option>
                        <Option value='M_Chimica'>Inginerie chimică</Option>
                        <Option value='M_Electrica'>Inginerie electrică</Option>
                        <Option value='M_ETTI'>Inginerie electronică, telecomunicaţii și tehnologii informaționale</Option>
                        <Option value='M_Energetica'>Inginerie energetică</Option>
                        <Option value='M_Industriala'>Inginerie industrială</Option>
                        <Option value='M_Mecanica'>Inginerie mecanică</Option>
                        <Option value='M_Management'>Inginerie şi management</Option>
                        <Option value='M_Mecatronica'>Mecatronică şi robotică</Option>
                        <Option value='M_Aplicate'>Ştiinţe inginereşti aplicate</Option>
                        <Option value='M_Comunicare'>Stiințe ale comunicării</Option>
                        <Option value='M_Relatii'>Relații internaționale și studii europene</Option>
                        <Option value='M_Economie'>Economie</Option>
                        <Option value='M_Educatie'>Științe ale educației</Option>
                      </Select>
                    </Form.Item>
                </Col> */}
              </Row>
            </div>
            <div className={styles.secondLine}>
              <div className={styles.firstColumn}>
                <ConfirmationModal
                  nameButton={t('internships.addInternshipForm.cancel')}
                  modalText={t('internships.cancelMessage')}
                  titleButton={''}
                  handleFunction={handleCancel}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.green, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
                <ConfirmationModal
                  nameButton={programId ? t('internships.addInternshipForm.updateButton') : t('internships.addInternshipForm.saveButton')}
                  modalText={t('internships.saveMessage')}
                  titleButton={''}
                  handleFunction={handleSave}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.secondColor, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
              </div>
            </div>
          </CustomForm>
        </div>
      </Layout>
    );
  }
};

export default StudyProgramForm;
