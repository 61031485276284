import {  useState } from "react";
import styles from "./StudyPrograms.module.scss";
import { Button, Dropdown, Menu, MenuProps, Modal, Progress, Select, Steps, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import Search from 'antd/lib/input/Search';
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Layout from "../../Containers/Layout";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DownloadOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useHistory, useParams  } from "react-router-dom";
import { theme } from "../../theme";
import Dragger from "antd/lib/upload/Dragger";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useIsAdmin } from "../../utils/utilFunctions";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import type { ColumnsType } from 'antd/es/table';
import { deleteStudyProgram, getStudyPrograms } from "../../Requests/study-program-request";
import { getDomains } from "../../Requests/domain-requests";
import { getFaculties } from "../../Requests/faculty-requests";
import { StudyCycleEnum } from "../../Api";
import * as _ from 'lodash';
import { downloadExcel } from "../../utils/downloadUtils";
import { downloadAncFile, downloadAracisFile, uploadAncFile, uploadAracisFile } from "../../Requests/file-manager-requests";
import { downloadFile } from "../../utils/downloadUtils";
import moment from "moment";
import { dateFormat } from "../../utils/constants";
const { Option } = Select;

interface PageResponse<T> {
    data?: T[] | null,
    totalCount?: number,
    page?: number,
    pageSize?: number
}

export enum ActionKeyEnum {
    Edit,
    Delete,
    ExternalEvaluation,
    AdminBoardDecision,
    SenateDecision,
    AncUFile,
    AncDFile,
    AracisUFile,
    AracisDFile,
}

export type PageCallBack<T> = (currentPage: number, pageSize: number) => Promise<PageResponse<T> | null | undefined>;
export type ColumnType<T> = { title: string, key: any, dataIndex?: string, width?: any, render?: ((text: string, record: T, index: number) => any) | ((date: Date) => any) };

const StudyPrograms = () => {
    const { t } = useTranslation();
    const [searchTermName, setSearchTermName] = useState('');
    const [searchTermDomain, setSearchTermDomain] = useState('00000000-0000-0000-0000-000000000000');
    const [searchTermType, setSearchTermType] = useState('');
    const [searchTermFaculty, setSearchTermFaculty] = useState('00000000-0000-0000-0000-000000000000');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrent] = useState(1);
    const history = useHistory();
    const isAdmin = useIsAdmin();

    const [modalProps, setModalProps] = useState({
        isModalVisible: false,
        title: '',
        fileExtension: '',
        studyProgramId: '',
        uploadHandler: uploadAncFile,
    });

    const { studyCycle } = useParams<{ studyCycle: StudyCycleEnum }>();

    const getActions = (record: any): ItemType[] | undefined => {
        let actions : MenuProps['items'] = [
            {
                label: 'Editare program',
                key: ActionKeyEnum.Edit,
                icon:
                <Tooltip title='Editare program'>
                    <FontAwesomeIcon icon={solid('edit')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Ștergere program',
                key: ActionKeyEnum.Delete,
                icon:
                <Tooltip title='Ștergere program'>
                    <FontAwesomeIcon icon={solid('trash')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Evaluări externe',
                key: ActionKeyEnum.ExternalEvaluation,
                icon:
                <Tooltip title='Evaluări externe'>
                    <FontAwesomeIcon icon={solid('circle-check')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Hotărâri CA',
                key: ActionKeyEnum.AdminBoardDecision,
                icon:
                <Tooltip title='Hotărâri CA'>
                    <FontAwesomeIcon icon={solid('circle-check')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Hotărâri Senat',
                key: ActionKeyEnum.SenateDecision,
                icon:
                <Tooltip title='Hotărâri Senat'>
                    <FontAwesomeIcon icon={solid('circle-check')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Fișă ANC',
                key: ActionKeyEnum.AncUFile,
                icon:
                <Tooltip title='Încarcă fișă ANC'>
                    <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>

            },
            {
                label: 'Fișă ANC',
                key: ActionKeyEnum.AncDFile,
                icon:
                <Tooltip title='Descarcă fișă ANC'>
                    <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>
            },
            {
                label: 'Fișă ARACIS',
                key: ActionKeyEnum.AracisUFile,
                icon:
                <Tooltip title='Încarcă fișă ARACIS'>
                    <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>
            },
            {
                label: 'Fișă ARACIS',
                key: ActionKeyEnum.AracisDFile,
                icon:
                <Tooltip title='Descarcă fișă ARACIS'>
                    <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>
            }


        ];
        return actions;
    }
    const [modalPropsDelete, setModalPropsDelete] = useState({
        isModalVisible: false,
        title: '',
        fileExtension: '',
        studyProgramId: '',
        //uploadHandler: uploadCVs
    });

    const [modalPropsStatus, setModalPropsStatus] =
    useState<{  isModalVisible: boolean,
                title: string,
                proposal: any | undefined
            }>
    ({
        isModalVisible: false,
        title: '',
        proposal: undefined,
    });

    const handleShow = (studyProgramId: string, uploadHandler: any, fileExtension: string = 'pdf') => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: true, studyProgramId, uploadHandler, fileExtension}));
    }

    const handleClose = () => {
        console.log('aocolasd');
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOk = () => {
        console.log('aoco');
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));

    }

    const handleShowDelete = (studyprogramId: string) => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: true, studyprogramId}));
    }

    const handleCloseDelete = () => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOkDelete = () => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: false}));
        deleteStudyProgram((modalPropsDelete.studyProgramId))
                .then(() => {
                    deleteSucceded();
                    invalidateQuery();
                })
                .catch(deleteFailed);

    }

    const handleShowStatus = (proposal: any) => {
        setModalPropsStatus(modalPropsStatus => ({...modalPropsStatus, isModalVisible: true, proposal}));
    }

    const handleCloseStatus = () => {
        setModalPropsStatus(modalPropsStatus => ({...modalPropsStatus, isModalVisible: false}));
    }


    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrent(() => pagination.current ?? 1);
        setPageSize(() => pagination.pageSize ?? 10);
    };

    const uploadFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Încarcarea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };


    const uploadSucceded = () => {
        handleClose();
        openNotification(
            'Succes',
            'Încarcarea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
            );
        };

    const validationSucceded = () => {
        openNotification(
            'Succes',
            'Validarea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const validationFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Validarea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const deleteSucceded = () => {
        openNotification(
            'Succes',
            'Ștergerea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const deleteFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Ștergerea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const wrongFile = () => {
        openNotification(
            'Eroare',
            'Extensia fișierului selectat nu este permisă!',
            NOTIFICATION_TYPES.ERROR
        );
    };


    const upload = async (file: Blob | null) => {
        if (file) {            
            await modalProps.uploadHandler(modalProps.studyProgramId, file).then(uploadSucceded);

        }
    }

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('getProposalPage') };

    const openFetchError = (_error: any) => {
        openNotification(
            'Eroare!',
            'Datele nu au putut fi aduse din server!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['getStudyProgramPage', searchTermName, searchTermDomain, searchTermFaculty, pageSize, currentPage, studyCycle],
        () => {
            let facultySearch = [searchTermFaculty];
            let domainSearch = [searchTermDomain];
            return getStudyPrograms(searchTermName, facultySearch, domainSearch, currentPage, pageSize, studyCycle);
        },
        {
            onError: openFetchError,
        }
    );

    const onMenuClick = (e: any, record: any) => {
        if (e.key == ActionKeyEnum.Edit)
        {
            history.push('/' + studyCycle + '/editare-program/' + record.id);
            return;
        }
        else if (e.key == ActionKeyEnum.Delete)
        {
            handleShowDelete(record.id!);
            return;
        }
        else if (e.key == ActionKeyEnum.ExternalEvaluation)
        {
            history.push('/' + studyCycle + '/evaluari-externe/' + record.id);
            return;
        }
        else if (e.key == ActionKeyEnum.AdminBoardDecision)
        {
            history.push('/' + studyCycle + '/hotarari-ca/' + record.id);
            return;
        }
        else if (e.key == ActionKeyEnum.SenateDecision)
        {
            history.push('/' + studyCycle + '/hotarari-senat/' + record.id);
            return;
        }
        else if (e.key == ActionKeyEnum.AncUFile)
        {
            handleShow(record.id!, uploadAncFile, 'pdf');
            return;
        }
        else if (e.key == ActionKeyEnum.AncDFile)
        {
            downloadFile(downloadAncFile(record.id), 'Fișă ANC din ' + moment(record.ancNoticeDate).format(dateFormat));
            return;
        }
        else if (e.key == ActionKeyEnum.AracisUFile)
        {
            handleShow(record.id!, uploadAracisFile, 'pdf');
            return;
        }
        else if (e.key == ActionKeyEnum.AracisDFile)
        {
            downloadFile(downloadAracisFile(record.id), 'Fișă ARACIS din ' + moment(record.aracisNoticeDate).format(dateFormat));
            return;
        }
    };

    const FilterByNameInput = (
        <Search placeholder={t('account.name')} allowClear onSearch={setSearchTermName} style={{ width: 200 }} />
    );

    //const domains = getDomains(undefined, undefined, 1, 20);

    const { data: domains } = useQuery(['getDomains'],
        () => {
            return getDomains(undefined, undefined, undefined, 1, 20);
        },
        {
            onError: openFetchError,
        }
    );

    const { data: faculties } = useQuery(['getFaculties'],
        () => {
            return getFaculties(undefined, 1, 20);
        },
        {
            onError: openFetchError,
        }
    );

    const getOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: "00000000-0000-0000-0000-000000000000"
            }
        ];

        let groups = _.groupBy(domains?.data, 'fundamentalName');
        
        for (const [key, value] of Object.entries(groups)) {
            options.push({
                label: key,
                options: value.map((option: any) => (
                    {label: option.name, value: option.id}
                ) ?? [])
            });
        }
        return options;
    }


    const FilterByDomainInput = (
        <Select defaultValue="Toate" style={{ width: "400px" }} onChange={setSearchTermDomain}
            options={getOptions()}>
        </Select>
    );

    const FilterByFacultyInput = (
        <Select defaultValue="Toate" style={{ width: "400px" }} onChange={setSearchTermFaculty}>
            { [<Option key={"00000000-0000-0000-0000-000000000000"}>{"Toate"}</Option>].concat(
            faculties?.map((option: any) => (
                <Option key={option.id}>{option.name}</Option>
            )) ?? [] )}
        </Select>
    );


    const columns : ColumnsType<any> = [
        {
            title: FilterByFacultyInput,
            dataIndex: 'facultyName',
            key: 'facultyName',
            width: 400,
        },
        {
            title: FilterByDomainInput,
            dataIndex: 'domainName',
            key: 'domainName',
            render: (text: string, record: any, index: number) => {
                return text;
            },
            width: 400,
        },
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            render: (text: string, record: any, index: number) => {
                return text;
            },
            width: 250,
        },
        {
            title: 'Nr. ECTS',
            key: 'numberECTS',
            dataIndex: 'numberECTS',
        },
        {
            title: 'Acreditare',
            key: 'accreditationType',
            dataIndex: 'accreditationType',
        },
        {
            title: 'Capacitate de scolarizare',
            key: 'capacity',
            dataIndex: 'capacity',
        },
        {
            title: 'Domeniu educational ISCED 2013',
            key: 'educaionalDomainISCED',
            dataIndex: 'educaionalDomainISCED',
        },
        {
            title: 'Cod COR',
            key: 'codCOR',
            dataIndex: 'codCOR',
        },
        {
            title: 'Denumire ocupatie',
            key: 'ocupationName',
            dataIndex: 'ocupationName',
        },
        {
            title: 'Nr. Aviz ANC',
            key: 'ancNoticeNumber',
            dataIndex: 'ancNoticeNumber',
        },
        {
            title: 'Data Aviz ANC',
            key: 'ancNoticeDate',
            dataIndex: 'ancNoticeDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Data Expirare ANC',
            key: 'ancExpireDate',
            dataIndex: 'ancExpireDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Data Expirare ARACIS',
            key: 'aracisExpireDate',
            dataIndex: 'aracisExpireDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Istoric Evaluari Externe',
            key: 'externalEvaluationHistory',
            dataIndex: 'externalEvaluationHistory',
        },
        {
            title: 'Data ultimei evaluari',
            key: 'lastEvaluationDate',
            dataIndex: 'lastEvaluationDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Responsabil program',
            key: 'programSupervisor',
            dataIndex: 'programSupervisor',
        },
        {
            title: 'Acțiuni',
            fixed: 'right',
            width: '50px',
            render: (text: string, record: any, index: number) =>
                <Dropdown placement="bottomLeft"
                    menu={{
                        onClick: (e) => onMenuClick(e, record),
                        items: getActions(record)
                    }}
                >
                    <FontAwesomeIcon icon={solid('bars')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px", marginLeft: "13px"}}/>
                </Dropdown>
        }
    ];


    const reportColumns: ColumnType<any>[] = [
        {
            title: 'Facultatea',
            key: 'facultyName',
        },
        {
            title: 'Domeniu',
            key: 'domainName',
        },
        {
            title: 'Specializarea/Program de studii universitare de licenţă  şi limba de predare',
            key: 'name',
        },
        {
            title: 'Nr. ECTS',
            key: 'numberECTS',
        },
        {
            title: 'Acreditare (A)/ Autorizare de funcționare provizorie (AP)',
            key: 'accreditationType',
        },
        {
            title: 'Capacitatea de școlarizare',
            key: 'capacity',
        },
        {
            title: 'Domeniu educational ISCED 2013',
            key: 'educaionalDomainISCED',
        },
        {
            title: 'Cod COR',
            key: 'codCOR',
        },
        {
            title: 'Denumire ocupatie',
            key: 'ocupationName',
        },
        {
            title: 'Nr. Aviz ANC',
            key: 'ancNoticeNumber',
            // render:(text: string, record: any, index: number) => {
            //     return record.ancNoticeNumber + "/" + record.ancNoticeDate?.toLocaleDateString("ro-RO");
            // }
        },
        {
            title: 'Data Aviz ANC',
            key: 'ancNoticeDate',
            // render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Data Expirare ANC',
            key: 'ancExpireDate',
            // render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Istoric Evaluari Externe',
            key: 'externalEvaluationHistory',
        },
        {
            title: 'Data ultimei evaluari',
            key: 'lastEvaluationDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Data Aviz ARACIS',
            key: 'aracisNoticeDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Data Expirare ARACIS',
            key: 'aracisExpireDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        
        
        {
            title: 'Responsabil program',
            key: 'programSupervisor',
            dataIndex: 'programSupervisor',
        },
    ];

    const [progress, setProgress] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState(false);

    const openErrorNotification = (error: string, message: string) => {
        openNotification(
            error,
            message,
            NOTIFICATION_TYPES.ERROR
        );
    }

    async function downloadAll<T>(getCall: PageCallBack<T>, setProgress: (progress: number) => void, onError: () => void) {
        let data = new Array<T>();
        let hasData = true;
        let pageIndex = 1;
        setProgress(0);

        do {
            const page = await getCall(pageIndex, 100).catch(onError);

            ++pageIndex;

            if (page?.data === undefined || page?.data === null) {
                onError();

                return;
            }

            data = data.concat(page.data);

            if ((pageIndex - 1) * 100 >= (page.totalCount ?? 0)) {
                hasData = false;
            }

            setProgress(hasData ? (Math.round((pageIndex - 1) * 100 / (page.totalCount ?? 1) * 100)) : 100)
        } while (hasData);

        return data;
    }

    const downloadReport = async () => {
        setDownloading(true);
        let facultySearch = [searchTermFaculty];
        let domainSearch = [searchTermDomain];
        let fileName = 'Raport Evaluări - ' + (studyCycle === StudyCycleEnum.Bachelor ? 'Licență - ' : 'Masterat - ') + (new Date()).toLocaleDateString("ro-RO");
        return downloadExcel(fileName, reportColumns.map(e => { return { header: e.title, key: e.key?.toString() ?? '' } }),
            await downloadAll((page, size) => getStudyPrograms(searchTermName, facultySearch, domainSearch, currentPage, pageSize, studyCycle), (value) => {
                setDownloadError(false);
                setProgress(value)
            }, () => {
                setDownloadError(true);
                openErrorNotification("Eroare!", "Raportul nu a putut fi descărcat cu succes!")
            }));
    }

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Modal
                open={modalPropsDelete.isModalVisible}
                onOk={handleOkDelete}
                onCancel={handleCloseDelete}
                title="Stergere"
                width={"50%"}
                okText={t('account.confirm')}
                cancelText={t('account.cancel')}
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        <Typography>Sigur doriti stergerea propunerii?</Typography>
                    </div>
            </Modal>
            <Modal
                open={modalProps.isModalVisible}
                closable={false}
                onOk={handleOk}
                onCancel={handleClose}
                title="Încărcare fișier"
                width={"50%"}
                cancelText='Anulare'
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { display: 'none' } }}
                >
                    <div>
                    <Dragger
                        multiple={false}
                        beforeUpload={(uploaded) => {
                            if (uploaded.name.includes(modalProps.fileExtension)) {
                                upload(uploaded).then(invalidateQuery).catch(uploadFailed);
                            }
                            else {
                                wrongFile();
                            }
                            return false;
                        }}
                        showUploadList={false}
                    >
                        <p className="ant-upload-drag-icon" >
                            <InboxOutlined style={{color: theme.green}}/>
                        </p>
                        <p className="ant-upload-text">Dă click aici sau trage fișierul pe care vrei să îl încarci în acest chenar</p>
                        <p className="ant-upload-hint">
                            Extensii acceptate: {modalProps.fileExtension}
                        </p>
                    </Dragger>
                    </div>
            </Modal>
            {downloading && <div onClick={() => setDownloading(false)} style={{cursor: "pointer"}}>
                <Progress percent={progress} status={downloadError ? "exception" : undefined} />
            </div>}
            <Table
                className={styles.usersTable}
                dataSource={data?.data || []}
                scroll={{ x: true }}
                pagination={{
                    total: data?.totalCount,
                    current: currentPage,
                    pageSize: data?.pageSize,
                    pageSizeOptions: ["10", "20", "50"],
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total: number, range: [number, number]) => {
                        return <div>
                                {isAdmin && <Tooltip title="Descărcare raport">
                                    <Button type="primary" icon={<DownloadOutlined />} style={{marginRight: 10}} onClick={downloadReport}>
                                        Descărcare raport
                                    </Button>
                                </Tooltip>}
                                <Tooltip title="Adaugă program">
                                    <Link to={{ pathname: '/' + studyCycle + '/adaugare-program'}} >
                                        <Button type="primary" icon={<PlusOutlined />}>
                                            Adaugă program
                                        </Button>
                                    </Link>
                                </Tooltip>
                            </div>
                    },
                    hideOnSinglePage: false,
                    locale: { items_per_page: t('configuration.pagination')},
                    position: ['topRight', 'bottomRight']
                }}
                locale={{
                    emptyText: (
                        <span>
                            <h3>Nu există programe adăugate! Apasă butonul de mai jos pentru a adăuga un program nou!</h3>
                            <Tooltip title="Adaugă program">
                                <Link to={{ pathname: '/' + studyCycle + '/adaugare-program'}} >
                                    <Button type="primary" icon={<PlusOutlined />}>
                                        Adaugă program
                                    </Button>
                                </Link>
                            </Tooltip>
                        </span>
                      )
                }}
                columns={columns}
                rowKey={record => record.id ?? ''}
                loading={isLoading}
                onChange={handleTableChange}
            />
        </Layout>
    </div>
}

export default StudyPrograms;
