import { useState } from "react";
import Layout from "../../../Containers/Layout";
import styles from "./DomainForm.module.scss";
import { Form, DatePicker, Select, Radio, Col, Row, Input } from "antd";
import CustomForm from "../../../CustomComponents/CustomForm";
import { theme } from "../../../theme";
import { Redirect, useParams } from "react-router-dom";
import {
  NOTIFICATION_TYPES,
  openNotification,
} from "../../../Components/Notifications/NotificationsUtils";
import { getUserId } from "../../../utils/utilFunctions";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useDebounce from "../../../Hooks/debounce";
import { getDomain, updateDomain } from "../../../Requests/domain-requests";
import { SimpleStudyCycleEnum } from "../../../Api";
import { addDomain } from "../../../Requests/domain-requests";
import { getFaculties } from "../../../Requests/faculty-requests";

const { RangePicker } = DatePicker;
const { Group } = Radio;
const { Option } = Select;
const dateFormatList = "YYYY-MM-DD";


const DomainForm = (props: any) => {
  const { t, i18n } = useTranslation();

  const openGetErrorNotification = (_error: any) => {
    openNotification(
      'Eroare',
      'Datele nu au putut fi aduse din server!',
      NOTIFICATION_TYPES.ERROR
    );
  };

  const currentUserId = getUserId();

  const [redirect, setRedirect] = useState(false);

  const [form] = Form.useForm<{
    name: string | null,
    fundamentalName: string | null,
    studyCycle: SimpleStudyCycleEnum,
    faculties: string[] | null,
  }>();

  const { domainId } = useParams<{ domainId: string }>();

  const { data: domain } = useQuery(["getDomain", domainId], () => getDomain(domainId), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {
      if (data) {
        form.setFieldsValue({
            name: data.name,
            fundamentalName: data.fundamentalName,
            studyCycle: data.studyCycle,
            faculties: data.faculties?.map(e => e.id!),
        });

      }
    },
    refetchOnWindowFocus: false
  });

  const { data: faculties } = useQuery(['getFaculties'],
        () => {
            return getFaculties(undefined, 1, 20);
        },
        {
            onError: openGetErrorNotification,
        }
    );

  const openSaveErrorNotification = (error: any) => {
    if (error.status === 400) {
      openNotification(
        'Eroare!',
        'Datele completate nu sunt valide!',
        NOTIFICATION_TYPES.ERROR
      );
    } else {
      openNotification(
        'Eroare!',
        'Propunerea nu a putut fi salvată!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  const handleCancel = () => {
    setRedirect(true);
  };

  const openSuccessNotificationAndRedirect = () => {
    setRedirect(true);
    openNotification(
      'Succes!',
      'Salvarea s-a efectuat cu succes!',
      NOTIFICATION_TYPES.SUCCESS,
      6
    );
  }


  const handleSave = () => {
    const state = form.getFieldsValue();
    console.warn(state);
    
   //TODO validation
    if (state.name !== "") {
      let obj = {
        name: state.name!,
        fundamentalName: state.fundamentalName!,
        studyCycle: state.studyCycle!,
        facultyIds: state.faculties
      };
      (domainId ? updateDomain(domainId, obj) : addDomain(obj))
      .then(openSuccessNotificationAndRedirect)
      .catch(openSaveErrorNotification);
    } else {
      openNotification(
        'Eroare!',
        'Datele introduse nu sunt valide!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };
  

  if (redirect) {
    return <Redirect to="/domenii" />;
  } else {
    return (
      <Layout>
        <div className={styles.container}>
          <CustomForm
            form={form}
            layout={theme.layout}
            action="/domenii"
            boxshadow={"none"}
          >
            <div className={styles.firstLine}>    

              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name='fundamentalName'
                    label='Denumirea domeniului fundamental:'
                    rules={[
                      {
                        required: true,
                        message: 'Denumirea domeniului fundamental este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <Input
                      placeholder='Completează denumirea domeniului de studii'
                      />
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item
                    name='name'
                    label='Denumirea domeniului de studii:'
                    rules={[
                      {
                        required: true,
                        message: 'Denumirea domeniului de studii este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <Input
                      placeholder='Completează denumirea domeniului de studii'
                      />
                  </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                      name='studyCycle'
                      label='Ciclu de învățământ:'
                      rules={[
                        {
                          required: true,
                          message: 'Selectarea ciclului de învățământ este obligatorie!',
                          whitespace: false,
                          
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%", textAlign: "left" }}
                        placeholder='Ciclul de învățământ'
                      >
                        <Option value={SimpleStudyCycleEnum.Bachelor}>Licență</Option>
                        <Option value={SimpleStudyCycleEnum.Master}>Masterat</Option>
                        
                      </Select>
                    </Form.Item>
                </Col>
                

                {/* select facultati */}
                <Col span={24}>
                    <Form.Item
                      name='faculties'
                      label='Facultăți asociate:'
                      rules={[
                        {
                          required: true,
                          message: 'Selectarea facultăților asociate este obligatorie!',
                          whitespace: false,
                          type: 'array',
                          
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        style={{ width: "100%", textAlign: "left" }}
                        placeholder='Alege facultățile asociate'
                      >
                        {faculties?.map((option: any) => (
                            <Option key={option.id}>{option.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={styles.secondLine}>
              <div className={styles.firstColumn}>
                <ConfirmationModal
                  nameButton={t('internships.addInternshipForm.cancel')}
                  modalText={t('internships.cancelMessage')}
                  titleButton={''}
                  handleFunction={handleCancel}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.green, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
                <ConfirmationModal
                  nameButton={domainId ? t('internships.addInternshipForm.updateButton') : t('internships.addInternshipForm.saveButton')}
                  modalText={t('internships.saveMessage')}
                  titleButton={''}
                  handleFunction={handleSave}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.secondColor, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
              </div>
            </div>
          </CustomForm>
        </div>
      </Layout>
    );
  }
};

export default DomainForm;
